import * as Configuration from './configuration'
import * as Data from './data'
import * as Payments from './payments'
import * as Processes from '@rushplay/processes'
import * as Redux from 'redux'

import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly'
import {postMessagingMiddleware} from './post-messaging-middleware'
import reduxEffectsFetchMiddleware from 'redux-effects-fetch'
import reduxEffectsMiddleware from 'redux-effects'
import reduxMultiMiddleware from 'redux-multi'

/**
 * Creates the redux store & sets up middlewares
 * @param {Object} initialState redux state
 * @returns {Object} Redux store
 */
export function createStore(initialState) {
  return Redux.createStore(
    Redux.combineReducers({
      configuration: Configuration.reducer,
      data: Data.reducer,
      payments: Payments.reducer,
      processes: Processes.reducer,
    }),
    initialState,
    composeWithDevTools(
      Redux.applyMiddleware(
        reduxMultiMiddleware,
        postMessagingMiddleware,
        // Prefills payment stuff
        Payments.createMiddleware({
          briteConfig(state) {
            return Configuration.getBriteConfig(state)
          },
          countryCode(state) {
            return Configuration.getCountryCode(state)
          },
          currency(state) {
            return Configuration.getCurrency(state)
          },
          locale(state) {
            return Configuration.getPaymentsLocale(state)
          },
          language(state) {
            return Configuration.getLanguage(state)
          },
          piqConfig(state) {
            return Configuration.getPiqConfig(state)
          },
          payustConfig(state) {
            return Configuration.getPayustConfig(state)
          },
          luqapayConfig(state) {
            return Configuration.getLuqapayConfig(state)
          },
          megapayConfig(state) {
            return Configuration.getMegapayConfig(state)
          },
          piqkycConfig(state) {
            return Configuration.getPiqKycConfig(state)
          },
          projsConfig(state) {
            return Configuration.getProjsConfig(state)
          },
          pwclickConfig(state) {
            return Configuration.getPwclickConfig(state)
          },
          nodapayConfig(state) {
            return Configuration.getNodapayConfig(state)
          },
          transferWorldConfig(state) {
            return Configuration.getTransferWorldConfig(state)
          },
          helloCleverConfig(state) {
            return Configuration.getHelloCleverConfig(state)
          },
          sessionId(state) {
            return Configuration.getToken(state)
          },
          state(state) {
            return state.payments
          },
          userId(state) {
            return Configuration.getUserId(state)
          },
          vevoPayConfig(state) {
            return Configuration.getVevopayConfig(state)
          },
          zimplerConfig(state) {
            return Configuration.getZimplerConfig(state)
          },
        }),
        reduxEffectsMiddleware,
        reduxEffectsFetchMiddleware
      )
    )
  )
}
