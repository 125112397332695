import * as R from 'ramda'
import * as brite from './brite'
import * as effects from './commands'
import * as helloClever from './hello-clever'
import * as luqapay from './luqapay'
import * as megapay from './megapay'
import * as nodapay from './nodapay'
import * as payust from './payust'
import * as piq from './paymentiq'
import * as piqkyc from './piqkyc'
import * as projs from './projs'
import * as pwclick from './pwclick'
import * as selectors from './selectors'
import * as transferWorld from './transfer-world'
import * as vevoPay from './vevo-pay'
import * as zimpler from './zimpler'

import {
  PAYMENTS_API_REQUEST,
  renderBodyTemplate,
  renderUrlTemplate,
} from './request'

import {BackendType} from './constants'
import {createStructuredSelector} from 'reselect'
import {fetch} from 'redux-effects-fetch'

/**
 * Brite configuration
 * @typedef {!Object} BriteConfiguration
 * @property {string} apiUrl
 *   Base API URL used to construct endpoint URLs
 * @property {!boolean|Array.<BackendServiceType>} enabled
 *   Either `true` when backend may be used, `false` otherwise, or
 *   comma-separated list of services to be used
 */

/**
 * Zimpler configuration
 * @typedef {!Object} ZimplerConfiguration
 * @property {string} apiUrl
 *   Base API URL used to construct endpoint URLs
 * @property {!boolean|Array.<BackendServiceType>} enabled
 *   Either `true` when backend may be used, `false` otherwise, or
 *   comma-separated list of services to be used
 */

/**
 * Payust configuration
 * @typedef {!Object} PayustConfiguration
 * @property {string} apiUrl
 *   Base API URL used to construct endpoint URLs
 * @property {!boolean|Array.<BackendServiceType>} enabled
 *   Either `true` when backend may be used, `false` otherwise, or
 *   comma-separated list of services to be used
 */

/**
 * LuqaPay configuration
 * @typedef {!Object} LuqapayConfiguration
 * @property {string} apiUrl
 *   Base API URL used to construct endpoint URLs
 * @property {!boolean|Array.<BackendServiceType>} enabled
 *   Either `true` when backend may be used, `false` otherwise, or
 *   comma-separated list of services to be used
 */

/**
 * MegaPay configuration
 * @typedef {!Object} MegapayConfiguration
 * @property {string} apiUrl
 *   Base API URL used to construct endpoint URLs
 * @property {!boolean|Array.<BackendServiceType>} enabled
 *   Either `true` when backend may be used, `false` otherwise, or
 *   comma-separated list of services to be used
 */

/**
 * PaymentIQ configuration
 * @typedef {!Object} PiqConfiguration
 * @property {string} apiUrl
 *   Base API URL used to construct endpoint URLs
 * @property {!boolean|Array.<BackendServiceType>} enabled
 *   Either `true` when backend may be used, `false` otherwise, or
 *   comma-separated list of services to be used
 * @property {string} merchantId
 *   Merchant which will process the payment (assigned by Payment IQ)
 */

/**
 * Projs configuration
 * @typedef {!Object} ProjsConfiguration
 * @property {string} apiUrl
 *   Base API URL used to construct endpoint URLs
 * @property {!boolean|Array.<BackendServiceType>} enabled
 *   Either `true` when backend may be used, `false` otherwise, or
 *   comma-separated list of services to be used.
 * @property {string} appId
 *   Application which will process the payment (assigned by Projs)
 */

/**
 * Nodapay configuration
 * @typedef {!Object} NodapayConfiguration
 * @property {string} apiUrl
 *   Base API URL used to construct endpoint URLs
 * @property {!boolean|Array.<BackendServiceType>} enabled
 *   Either `true` when backend may be used, `false` otherwise, or
 *   comma-separated list of services to be used.
 * @property {string} appId
 *   Application which will process the payment (assigned by Projs)
 */

/**
 * @callback BriteConfigResolver
 * @param {any} state Application state
 * @returns {!BriteConfiguration} Brite configuration
 */

/**
 * @callback ZimplerConfigResolver
 * @param {any} state Application state
 * @returns {!ZimplerConfiguration} Zimpler configuration
 */

/**
 * @callback PayustConfigResolver
 * @param {any} state Application state
 * @returns {!PayustConfiguration} Payust configuration
 */

/**
 * @callback LuqaPayConfigResolver
 * @param {any} state Application state
 * @returns {!uqaPayConfiguration} uqaPay configuration
 */

/**
 * @callback MegaPayConfigResolver
 * @param {any} state Application state
 * @returns {!uqaPayConfiguration} uqaPay configuration
 */

/**
 * @callback CountryCodeResolver
 * @param {any} state Application state
 * @returns {!string} Country code
 */

/**
 * @callback CurrencyResolver
 * @param {any} state Application state
 * @returns {!string} Transaction currency as ISO 4217 code
 */

/**
 * @callback LocaleResolver
 * @param {any} state Application state
 * @returns {!string} Player’s locale as ISO 639-1 code
 */

/**
 * @callback LanguageResolver
 * @param {any} state Application state
 * @returns {!string} Player’s language as ISO 639-1 code
 */

/**
 * @callback PaymentsStateResolver
 * @param {any} state Application state
 * @returns {!PaymentsState} Payments substate of main state
 */

/**
 * @callback PiqConfigResolver
 * @param {any} state Application state
 * @returns {!PiqConfiguration} PaymentIQ configuration
 */

/**
 * @callback ProjsConfigResolver
 * @param {any} state Application state
 * @returns {!ProjsConfiguration} Projs configuration
 */

/**
 * @callback NodapayConfigResolver
 * @param {any} state Application state
 * @returns {!NodapayConfiguration} NodaPay configuration
 */

/**
 * @callback SessionIdResolver
 * @param {any} state Application state
 * @returns {SessionId} Player’s session ID (token)
 */

/**
 * @callback UserIdResolver
 * @param {any} state Application state
 * @returns {UserId} Player's unique ID
 */

/**
 * @returns {BriteConfiguration|PiqConfiguration|ProjsConfiguration|NodapayConfiguration|ZimplerConfiguration|PayustConfiguration|LuqapayConfiguration|MegapayConfiguration} Default configuration
 */
function defaultConfigResolver() {
  return {enabled: false}
}

function isEnabled(config, transactionType) {
  if (typeof config.enabled === 'boolean') {
    return config.enabled
  }

  return R.includes(transactionType, config.enabled)
}

/**
 * PaymentIQ API middleware.
 *
 * Adds data to API requests based on application state.
 *
 * @param {Object} resolvers Redux selectors to retrieve data from state
 * @param {BriteConfigResolver} [resolvers.briteConfig=defaultConfigResolver]
 * @param {ZimplerConfigResolver} [resolvers.zimplerConfig=defaultConfigResolver]
 * @param {PayustConfigResolver} [resolvers.payustConfig=defaultConfigResolver]
 * @param {LuqapayConfigResolver} [resolvers.luqapayConfig=defaultConfigResolver]
 * @param {MegapayConfigResolver} [resolvers.megapayConfig=defaultConfigResolver]
 * @param {CountryCodeResolver} resolvers.countryCode
 * @param {CurrencyResolver} resolvers.currency
 * @param {LanguageResolver} resolvers.language
 * @param {LocaleResolver} resolvers.locale
 * @param {PiqConfigResolver} [resolvers.piqConfig=defaultConfigResolver]
 * @param {ProjsConfigResolver} [resolvers.projsConfig=defaultConfigResolver]
 * @param {NodapayConfigResolver} [resolvers.nodapayConfig=defaultConfigResolver]
 * @param {SessionIdResolver} resolvers.sessionId
 * @param {PaymentsStateResolver} resolvers.state
 * @param {UserIdResolver} resolvers.userId
 */
export function createMiddleware(resolvers) {
  const getConfig = createStructuredSelector({
    [BackendType.BRITE]: resolvers.briteConfig || defaultConfigResolver,
    [BackendType.ZIMPLER]: resolvers.zimplerConfig || defaultConfigResolver,
    [BackendType.PAYUST]: resolvers.payustConfig || defaultConfigResolver,
    [BackendType.LUQAPAY]: resolvers.luqapayConfig || defaultConfigResolver,
    [BackendType.MEGAPAY]: resolvers.megapayConfig || defaultConfigResolver,
    [BackendType.PIQ]: resolvers.piqConfig || defaultConfigResolver,
    [BackendType.PIQKYC]: resolvers.piqkycConfig || defaultConfigResolver,
    [BackendType.PROJS]: resolvers.projsConfig || defaultConfigResolver,
    [BackendType.PWCLICK]: resolvers.pwclickConfig || defaultConfigResolver,
    [BackendType.NODAPAY]: resolvers.nodapayConfig || defaultConfigResolver,
    [BackendType.TRANSFERWORLD]:
      resolvers.transferWorldConfig || defaultConfigResolver,
    [BackendType.HELLOCLEVER]:
      resolvers.helloCleverConfig || defaultConfigResolver,
    [BackendType.VEVOPAY]: resolvers.vevoPayConfig || defaultConfigResolver,
  })

  return (store) => (next) => (action) => {
    const state = store.getState()
    const config = getConfig(state)

    if (action.type === PAYMENTS_API_REQUEST) {
      const apiUrl = R.path([action.payload.backendType, 'apiUrl'], config)

      return next(
        fetch(
          renderUrlTemplate(
            `${apiUrl}${action.payload.urlTemplate}`,
            action.payload.requestData
          ),
          {
            body: R.isEmpty(action.payload.bodyTemplate)
              ? null
              : JSON.stringify(
                  renderBodyTemplate(
                    action.payload.bodyTemplate,
                    action.payload.requestData
                  )
                ),
            headers: R.merge(
              {'Content-Type': 'application/json'},
              action.payload.requestHeaders
            ),
            method: action.payload.requestMethod,
          }
        )
      )
    }

    switch (action.type) {
      case effects.PAYMENT_METHODS_UPDATE_REQUESTED: {
        const txType = action.payload.transactionType
        const sessionId = resolvers.sessionId(state)
        const authenticated = Boolean(sessionId)

        const enabled = {
          [BackendType.BRITE]: isEnabled(config[BackendType.BRITE], txType),
          [BackendType.ZIMPLER]: isEnabled(config[BackendType.ZIMPLER], txType),
          [BackendType.PAYUST]: isEnabled(config[BackendType.PAYUST], txType),
          [BackendType.LUQAPAY]: isEnabled(config[BackendType.LUQAPAY], txType),
          // [BackendType.MEGAPAY]: false,
          [BackendType.MEGAPAY]: isEnabled(config[BackendType.MEGAPAY], txType),
          [BackendType.PIQ]:
            authenticated && isEnabled(config[BackendType.PIQ], txType),
          [BackendType.PIQKYC]:
            !authenticated && isEnabled(config[BackendType.PIQKYC], txType),
          [BackendType.PROJS]: isEnabled(config[BackendType.PROJS], txType),
          [BackendType.PWCLICK]: isEnabled(config[BackendType.PWCLICK], txType),
          [BackendType.NODAPAY]: isEnabled(config[BackendType.NODAPAY], txType),
          [BackendType.TRANSFERWORLD]: isEnabled(
            config[BackendType.TRANSFERWORLD],
            txType
          ),
          [BackendType.HELLOCLEVER]: isEnabled(
            config[BackendType.HELLOCLEVER],
            txType
          ),
          [BackendType.VEVOPAY]: isEnabled(config[BackendType.VEVOPAY], txType),
        }

        if (enabled[BackendType.BRITE]) {
          store.dispatch(
            brite.fetchPaymentMethods({
              countryCode: resolvers.countryCode(state),
              transactionType: action.payload.transactionType,
            })
          )
        }

        if (enabled[BackendType.ZIMPLER]) {
          store.dispatch(
            zimpler.fetchPaymentMethods({
              countryCode: resolvers.countryCode(state),
              transactionType: action.payload.transactionType,
            })
          )
        }

        if (enabled[BackendType.PAYUST]) {
          store.dispatch(
            payust.fetchPaymentMethods({
              countryCode: resolvers.countryCode(state),
              transactionType: action.payload.transactionType,
              sessionId: resolvers.sessionId(state),
            })
          )
        }

        if (enabled[BackendType.LUQAPAY]) {
          store.dispatch(
            luqapay.fetchPaymentMethods({
              countryCode: resolvers.countryCode(state),
              transactionType: action.payload.transactionType,
              sessionId: resolvers.sessionId(state),
            })
          )
        }

        if (enabled[BackendType.MEGAPAY]) {
          store.dispatch(
            megapay.fetchPaymentMethods({
              countryCode: resolvers.countryCode(state),
              transactionType: action.payload.transactionType,
              sessionId: resolvers.sessionId(state),
            })
          )
        }

        if (enabled[BackendType.PIQ]) {
          store.dispatch(
            piq.fetchPaymentMethodsByUser({
              attributes: action.payload.attributes,
              channelId: action.payload.channelId,
              locale: resolvers.locale(state),
              merchantId: config[BackendType.PIQ].merchantId,
              // TODO: allow to filter it via selector
              showExpired: action.payload.showExpired,
              // TODO: allow to filter it via selector
              // "NEW" status means that user tried to perform a transaction, but it have failed, was never processed successfully, and should not be visualized in the cashier.
              showNew: false,
              sessionId: resolvers.sessionId(state),
              transactionType: action.payload.transactionType,
              userId: resolvers.userId(state),
            })
          )
        }

        if (enabled[BackendType.PIQKYC]) {
          store.dispatch(
            piqkyc.fetchPaymentMethods({
              providerType: config[BackendType.PIQKYC].providerType,
              transactionType: action.payload.transactionType,
            })
          )
        }

        if (enabled[BackendType.PROJS]) {
          store.dispatch(
            projs.fetchPaymentMethods({
              transactionType: action.payload.transactionType,
            })
          )
        }

        if (enabled[BackendType.PWCLICK]) {
          store.dispatch(
            pwclick.fetchPaymentMethods({
              countryCode: resolvers.countryCode(state),
              transactionType: action.payload.transactionType,
              sessionId: resolvers.sessionId(state),
            })
          )
        }

        if (enabled[BackendType.NODAPAY]) {
          store.dispatch(
            nodapay.fetchPaymentMethods({
              countryCode: resolvers.countryCode(state),
              transactionType: action.payload.transactionType,
              sessionId: resolvers.sessionId(state),
            })
          )
        }

        if (enabled[BackendType.TRANSFERWORLD]) {
          store.dispatch(
            transferWorld.fetchPaymentMethods({
              transactionType: action.payload.transactionType,
              sessionId: resolvers.sessionId(state),
            })
          )
        }

        if (enabled[BackendType.HELLOCLEVER]) {
          store.dispatch(
            helloClever.fetchPaymentMethods({
              transactionType: action.payload.transactionType,
              sessionId: resolvers.sessionId(state),
            })
          )
        }

        if (enabled[BackendType.VEVOPAY]) {
          store.dispatch(
            vevoPay.fetchPaymentMethods({
              transactionType: action.payload.transactionType,
              sessionId: resolvers.sessionId(state),
            })
          )
        }

        return next(action)
      }

      case effects.PLAYER_TRANSACTIONS_UPDATE_REQUESTED: {
        if (
          isEnabled(config[BackendType.BRITE], action.payload.transactionType)
        ) {
          store.dispatch(
            brite.fetchPendingTransactions({
              sessionId: resolvers.sessionId(state),
              transactionType: action.payload.transactionType,
            })
          )
        }

        if (
          isEnabled(config[BackendType.PIQ], action.payload.transactionType)
        ) {
          store.dispatch(
            piq.fetchPendingTransactions({
              locale: resolvers.locale(state),
              maxDate: action.payload.maxDate,
              merchantId: config[BackendType.PIQ].merchantId,
              minDate: action.payload.minDate,
              paymentMethodId: action.payload.paymentMethodId,
              sessionId: resolvers.sessionId(state),
              states: action.payload.states,
              transactionId: action.payload.transactionId,
              transactionType: action.payload.transactionType,
              userId: resolvers.userId(state),
            })
          )
        }

        return next(action)
      }

      case effects.TRANSACTION_CANCELLATION_REQUESTED: {
        const transaction = selectors.getPendingTransaction(
          resolvers.state(state),
          {
            transactionId: action.payload.transactionId,
          }
        )

        switch (action.payload.backendType || transaction.backendType) {
          case BackendType.BRITE: {
            store.dispatch(
              brite.cancelPendingTransaction({
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
              })
            )
            break
          }

          case BackendType.ZIMPLER: {
            store.dispatch(
              zimpler.cancelPendingTransaction({
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
              })
            )
            break
          }

          case BackendType.PAYUST: {
            store.dispatch(
              payust.cancelPendingTransaction({
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
              })
            )
            break
          }

          case BackendType.LUQAPAY: {
            store.dispatch(
              luqapay.cancelPendingTransaction({
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
              })
            )
            break
          }

          case BackendType.MEGAPAY: {
            store.dispatch(
              megapay.cancelPendingTransaction({
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
              })
            )
            break
          }

          case BackendType.PIQ: {
            store.dispatch(
              piq.cancelPendingTransaction({
                merchantId: config[BackendType.PIQ].merchantId,
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
                userId: resolvers.userId(state),
              })
            )
            break
          }
        }

        return next(action)
      }

      case effects.TRANSACTION_START_REQUESTED: {
        const paymentMethod = selectors.getPaymentMethod(
          resolvers.state(state),
          {
            id: action.payload.paymentMethodId,
          }
        )

        switch (paymentMethod.backendType) {
          case BackendType.BRITE: {
            store.dispatch(brite.performTransaction())
            break
          }
          case BackendType.ZIMPLER: {
            store.dispatch(zimpler.performTransaction())
            break
          }
          case BackendType.PAYUST: {
            store.dispatch(
              payust.performTransaction(
                {
                  amountCents: action.payload.amountCents,
                  paymentMethodId: action.payload.paymentMethodId,
                  sessionId: resolvers.sessionId(state),
                  transactionType: paymentMethod.transactionType,
                  service: paymentMethod.service,
                },
                action.payload.transactionData
              )
            )
            break
          }

          case BackendType.LUQAPAY: {
            store.dispatch(
              luqapay.performTransaction(
                {
                  amountCents: action.payload.amountCents,
                  paymentMethodId: action.payload.paymentMethodId,
                  sessionId: resolvers.sessionId(state),
                  transactionType: paymentMethod.transactionType,
                  service: paymentMethod.service,
                },
                action.payload.transactionData
              )
            )
            break
          }

          case BackendType.MEGAPAY: {
            store.dispatch(
              megapay.performTransaction(
                {
                  amountCents: action.payload.amountCents,
                  paymentMethodId: action.payload.paymentMethodId,
                  sessionId: resolvers.sessionId(state),
                  transactionType: paymentMethod.transactionType,
                  service: paymentMethod.service,
                },
                action.payload.transactionData
              )
            )
            break
          }

          case BackendType.PIQ: {
            store.dispatch(
              piq.performTransaction(
                {
                  amountCents: action.payload.amountCents,
                  attributes: action.payload.attributes,
                  currency: resolvers.currency(state),
                  locale: resolvers.locale(state),
                  language: resolvers.language(state),
                  countryCode: resolvers.countryCode(state),
                  method: 'process',
                  merchantId: config[BackendType.PIQ].merchantId,
                  paymentMethodId: action.payload.paymentMethodId,
                  providerType: paymentMethod.providerType,
                  sessionId: resolvers.sessionId(state),
                  transactionType: paymentMethod.transactionType,
                  userId: resolvers.userId(state),
                },
                action.payload.transactionData
              )
            )
            break
          }

          case BackendType.PIQKYC: {
            store.dispatch(
              piqkyc.performTransaction(config[BackendType.PIQKYC].providerType)
            )
            break
          }

          case BackendType.PROJS: {
            store.dispatch(
              projs.performTransaction(
                {
                  amountCents: action.payload.amountCents,
                  appId: config[BackendType.PROJS].appId,
                  attributes: action.payload.attributes,
                  currency: resolvers.currency(state),
                  language: resolvers.language(state),
                  locale: resolvers.locale(state),
                  paymentMethodId: action.payload.paymentMethodId,
                  providerType: paymentMethod.providerType,
                  sessionId: resolvers.sessionId(state),
                  transactionType: paymentMethod.transactionType,
                },
                action.payload.transactionData
              )
            )
            break
          }

          case BackendType.PWCLICK: {
            store.dispatch(
              pwclick.performTransaction(
                {
                  amountCents: action.payload.amountCents,
                  appId: config[BackendType.PWCLICK].appId,
                  attributes: action.payload.attributes,
                  currency: resolvers.currency(state),
                  language: resolvers.language(state),
                  locale: resolvers.locale(state),
                  paymentMethodId: action.payload.paymentMethodId,
                  providerType: paymentMethod.providerType,
                  sessionId: resolvers.sessionId(state),
                  transactionType: paymentMethod.transactionType,
                },
                action.payload.transactionData
              )
            )
            break
          }

          case BackendType.NODAPAY: {
            store.dispatch(nodapay.performTransaction())

            break
          }

          case BackendType.TRANSFERWORLD: {
            store.dispatch(
              transferWorld.performTransaction(
                {
                  amountCents: action.payload.amountCents,
                  paymentMethodId: action.payload.paymentMethodId,
                  sessionId: resolvers.sessionId(state),
                  transactionType: paymentMethod.transactionType,
                },
                action.payload.transactionData
              )
            )
            break
          }

          case BackendType.HELLOCLEVER: {
            store.dispatch(
              helloClever.performTransaction(
                {
                  amountCents: action.payload.amountCents,
                  paymentMethodId: action.payload.paymentMethodId,
                  sessionId: resolvers.sessionId(state),
                  transactionType: paymentMethod.transactionType,
                },
                action.payload.transactionData
              )
            )
            break
          }

          case BackendType.VEVOPAY: {
            store.dispatch(
              vevoPay.performTransaction(
                {
                  amountCents: action.payload.amountCents,
                  paymentMethodId: action.payload.paymentMethodId,
                  sessionId: resolvers.sessionId(state),
                  transactionType: paymentMethod.transactionType,
                  service: paymentMethod.service,
                },
                action.payload.transactionData
              )
            )
            break
          }
        }

        return next(action)
      }

      // TODO: Make backend-independent
      // Currently transaction state update is handled only for PIQ
      // transactions. As commands are abstraction over multiple backends, this
      // must be done in the same way.
      // To allow it, active transaction’s backend ID (or payment method ID)
      // must be somewhere in Redux state.
      case effects.TRANSACTION_UPDATE_REQUESTED: {
        const paymentMethod = selectors.getPaymentMethod(
          resolvers.state(state),
          {
            id: action.payload.paymentMethodId,
          }
        )

        switch (paymentMethod.backendType) {
          case BackendType.PIQ: {
            store.dispatch(
              piq.fetchTransactionState({
                merchantId: config[BackendType.PIQ].merchantId,
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
                userId: resolvers.userId(state),
              })
            )
            break
          }

          case BackendType.HELLOCLEVER: {
            store.dispatch(
              helloClever.fetchTransactionState({
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
              })
            )
            break
          }

          case BackendType.PAYUST: {
            store.dispatch(
              payust.fetchTransactionState({
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
              })
            )
            break
          }

          case BackendType.LUQAPAY: {
            store.dispatch(
              luqapay.fetchTransactionState({
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
                transactionType: paymentMethod.transactionType,
              })
            )
            break
          }

          case BackendType.MEGAPAY: {
            store.dispatch(
              megapay.fetchTransactionState({
                sessionId: resolvers.sessionId(state),
                transactionId: action.payload.transactionId,
                transactionType: paymentMethod.transactionType,
              })
            )
            break
          }
        }

        return next(action)
      }

      default: {
        return next(action)
      }
    }
  }
}
